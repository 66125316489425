import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const organizationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrganization: builder.query({
            query: (organizationId) => `/organizations/${organizationId}`,
            providesTags: (result, error, arg) => [{ type: 'Organization', id: arg }]
        }),
        getOrganizationUsers: builder.query({
            query: (organizationId) => `/organizations/${organizationId}/users`,
            providesTags: (result = [], error, arg) =>
                result.data ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'User', id })),
                        { type: 'User', id: 'PARTIAL-LIST' }
                    ] :
                    [{ type: 'User', id: 'PARTIAL-LIST' }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ 
                        message: 'Could not load users at this time. Please try again later.' 
                    }));
                }  
            },
        }),
        getHospitalsInOrganization: builder.query({
            query: (organizationId) => `/organizations/${organizationId}/hospitals`,
            providesTags: (result, error, arg) => [{ type: 'Hospital', id: arg }]
        }),
    }),
});

export const {
    useGetOrganizationQuery,
    useGetOrganizationUsersQuery,
    useGetHospitalsInOrganizationQuery,
} = organizationSlice;
