import { Add, People } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOrganizationQuery } from '../../../api/apiSlice';
import LoadingIndicator from '../../LoadingIndicator';
import OrganizationConfiguration from './OrganizationConfiguration';

function OrganizationView() {
    const params = useParams();
    const navigate = useNavigate();
    const { data: organizationData, isLoading, isSuccess } = useGetOrganizationQuery(params.organizationId);

    function handleClick() {
        navigate('/superSettings/hospitals/create', { state: { organizationId: organizationData.id, organizationName: organizationData.name } });
    }

    return (
        isLoading && <LoadingIndicator /> ||
        !isLoading && isSuccess &&
        <>
            <Stack display='grid' alignItems='center'>
                <OrganizationConfiguration organization={organizationData} key={organizationData.id} />
            </Stack>
            <Stack ml={4} justifyContent='left' alignItems='left' spacing={2} p={4} boxShadow={2} backgroundColor='white' maxWidth='25%' borderRadius='8px'>
                <Typography variant='heavy' fontSize='20px'>Organization Actions</Typography>
                <Button
                    variant='text'
                    startIcon={<Add />}
                    onClick={() => handleClick()}
                >
                    Create New Hospital
                </Button>
                <Button variant='text' startIcon={<People />} onClick={() => navigate(`/superSettings/organizations/${params.organizationId}/users`)}>Manage Users</Button>
            </Stack>
        </>
    );
}

export default OrganizationView;