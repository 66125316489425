import Add from '@mui/icons-material/Add';
import { Card, CardActionArea, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetOrganizationsQuery } from '../../../api/apiSlice';
import { theme } from '../../../theme';
import LoadingIndicator from '../../LoadingIndicator';
import OrganizationCard from './OrganizationCard';

function SuperView() {
    const { data: organizations, isLoading } = useGetOrganizationsQuery();
    const navigate = useNavigate();

    function handleCreateOrg() {
        navigate('/superSettings/organizations/create');
    }

    return (
        <Grid container spacing={4} p={4}>
            { isLoading ? <LoadingIndicator /> : organizations?.map(org => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={org.id}>
                    <OrganizationCard organization={org} />
                </Grid>
            ))}
            {/* Create new org button */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card sx={{ padding: 2, height: '100%', alignContent: 'center', textAlign: 'center' }}>
                    <CardActionArea onClick={handleCreateOrg}>
                        <Typography variant='heavy' sx={{ fontSize: '18px', color: `${theme.palette.subtext.main}` }}><Add /><br />Create New Organization</Typography>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
    );
}

export default SuperView;