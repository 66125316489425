import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

function UserHospitals(props) {
    return (
        <Stack spacing={4} width='33%'>
            <Typography variant='heavy' fontSize='20px'>User Hospitals</Typography>
            <Stack spacing={2}>
                <FormControlLabel label='All Hospitals' control={<Checkbox 
                    checked={props.userDetails.hospitals.length === props.hospitals.length} 
                    onChange={(e) => props.handleAllHospitalsChange(e)}
                />} />
                { props.hospitals.map((hospital) => (
                    <FormControlLabel key={hospital.id} label={hospital.name} name={hospital.id} control={
                        <Checkbox 
                            checked={props.userDetails.hospitals.find((h) => h.id === hospital.id) !== undefined} 
                            onChange={(e) => props.handleHospitalChange(e)}    
                        />} />
                ))}
            </Stack>
        </Stack>
    );
}

export default UserHospitals;
