import React from 'react';
import { Stack, Typography, FormControlLabel, Checkbox } from '@mui/material';

function UserRoles(props) {
    return (
        <Stack justifyContent='space-between' width='33%'>
            <Stack spacing={4}>
                <Typography variant='heavy' fontSize='20px'>User Roles</Typography>
                <Stack spacing={2}>
                    { props.permissions.map((permission) => (
                        <FormControlLabel 
                            key={permission.id} 
                            label={permission.name} 
                            name={permission.id} 
                            control={<Checkbox checked={props.userDetails.roles.find((role) => role.id === permission.id) !== undefined} onChange={(e) => props.handleRoleChange(e)} />} 
                        />
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default UserRoles;
