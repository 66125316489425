import React from 'react';
import { Card, Button, Stack, Typography } from '@mui/material';
import logo from '../../../../assets/docsi_logo.svg';
import { theme } from '../../../theme';
import { useNavigate } from 'react-router-dom';

function OrganizationCard(props) {
    const navigate = useNavigate();

    function handleManage() {
        navigate(`/superSettings/organizations/${props.organization.id}`);
    }
  
    return (
        <Card sx={{ height: '100%' }}>
            <Stack spacing={2} justifyContent={'space-between'} height={'100%'} padding={3}>
                <Stack spacing={2}>
                    <img src={props.organization.logo ? props.organization.logo : logo} alt='Organization Logo' width='90%'/>
                    {/* Name and System Id */}
                    <Stack>
                        <Typography variant='heavy' sx={{ fontSize: '22px' }}>{props.organization?.name}</Typography>
                        <Typography variant='normal' sx={{ fontSize: '11px', color: `${theme.palette.subtext.main}` }}>Organization id: {props.organization?.id}</Typography>
                    </Stack>
                    {/* Details/Stats */}
                    <Typography variant='normal' sx={{ fontSize: '14px' }}>Hospitals: {props.organization?.hospitals.length}</Typography>
                    <Typography variant='normal' sx={{ fontSize: '14px' }}>Physicians: {props.organization?.physicians_count}</Typography>
                </Stack>
                {/* Edit button */}
                <Button variant='outlined' fullWidth onClick={handleManage} >Manage</Button>
            </Stack>
        </Card>
    );
}   

export default OrganizationCard;
